import * as React from "react";
import { graphql } from "gatsby";
import { AboutPageQuery } from "../../types/graphql-types";
import styled from "styled-components";
import { theme } from "../components/theme";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import { Meta } from "../components/Meta";

interface IAboutPageProps {
  data: AboutPageQuery;
}

const About: React.FC<IAboutPageProps> = ({ data }) => {
  const nodes = data.allContentfulAuthor.edges;
  return (
    <Layout>
      <Meta />
      <Helmet>
        <title>kame's blog - About</title>
      </Helmet>

      <Container>
        <h1 style={{ width: "100%", textAlign: "center" }}>About</h1>

        <Body>
          <p>
            世間的にはオッサンとよばれる歳になった兄弟で色々雑多な事を書いていきます
          </p>
          {nodes.map((auther) => {
            return (
              <p key={auther.node.name}>
                <strong>{auther.node.name}: </strong>
                {auther.node.about.about}
              </p>
            );
          })}
        </Body>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query AboutPage {
    allContentfulAuthor {
      edges {
        node {
          about {
            about
          }
          name
          avatorImage {
            file {
              url
            }
          }
        }
      }
    }
  }
`;

export default About;

const Container = styled.div`
  margin: 0 auto;
  padding: 0 12px;

  width: 100vw;
  @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
    width: ${theme.width.body};
    padding: 0;
  }
`;

const Body = styled.div`
  color: #000;
`;
